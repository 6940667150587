<template>
  <!-- 渠道配置 -->
  <div class="channel-config-page">
    <el-button type="primary" size="small" @click="addFun">添加</el-button>
    <Table
      :table-data.sync="tableData"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
      :showOverflowTooltip="false"
      operation-width="150"
    >
      <template slot-scope="scope">
        <el-button
          type="text"
          size="small"
          sort="primary"
          @click="updateBtn(scope.scopeRow)"
          >编辑</el-button
        >
      </template>
    </Table>
    <el-dialog
      v-if="showPopup"
      :title="headerText"
      :visible.sync="showPopup"
      width="1400px"
      :close-on-click-modal="false"
      @close="closePopupFun"
    >
      <el-form
        ref="form"
        :model="form"
        inline
        :rules="rules"
        label-width="140px"
        label-position="right"
        class="channelForm"
      >
        <h4>基本信息</h4>
        <el-row>
          <el-col :span="12">
            <el-form-item label="渠道代码：" prop="channelNo">
              <el-input
                v-model.trim="form.channelNo"
                maxlength="15"
                :disabled="headerText === '编辑'"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="渠道名称：" prop="channelName">
              <el-input
                v-model.trim="form.channelName"
                maxlength="10"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属公司：" prop="companyId">
              <companySelect
                ref="companySelectRef"
                :company-tree="companyList"
                :valuename="form.companyName"
                :value="form.companyId"
                :disabled-array="disabledArray"
                :disabled="headerText == '编辑'"
                @getValue="getAddGroupIdShow"
                @getName="getCompanyName"
              ></companySelect> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="结算日：" prop="settlementDay">
              每月
              <el-input
                v-model.trim="form.settlementDay"
                placeholder="请输入"
                type="number"
                class="little"
              ></el-input
              >号
            </el-form-item></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="快客码链接：" prop="kkCodeLink">
              <el-input
                v-model.trim="form.kkCodeLink"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="邀请有礼链接：" prop="invitationLink">
              <el-input
                v-model.trim="form.invitationLink"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <!-- <el-form-item label="投诉反馈链接：" prop="invitationLink">
          <el-input
            v-model.trim="form.invitationLink"
            placeholder="请输入"
          ></el-input>
        </el-form-item> -->
        <el-row>
          <el-col :span="12"
            ><el-form-item label="优惠券推广链接·：" prop="smartLink">
              <el-input
                v-model.trim="form.smartLink"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="小程序APPID：" prop="appId">
              <el-input
                v-model.trim="form.appId"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="小程序secret：" prop="secret">
              <el-input
                v-model.trim="form.secret"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="开票方式：" prop="invoiceMethod">
              <el-radio-group v-model="form.invoiceMethod">
                <el-radio :label="1">电子发票</el-radio>
                <el-radio :label="2">仅提交开票信息</el-radio>
                <el-radio :label="3">不开票</el-radio>
                <el-radio :label="4">数电发票</el-radio>
              </el-radio-group>
            </el-form-item></el-col
          >
        </el-row>
        <el-row>
          <el-col v-if="form.invoiceMethod != 3" :span="12">
            <el-form-item label="开票业务：" prop="invoiceBusinessType">
              <el-checkbox-group v-model="form.invoiceBusinessType">
                <div v-for="(item, index) in businessList" :key="index">
                  <el-checkbox :label="item.businessType">{{
                    item.name
                  }}</el-checkbox>
                </div>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- logo -->
            <el-form-item label="LOGO：" prop="logo">
              <el-upload
                ref="logoRef"
                action
                list-type="picture-card"
                class="upload-demo"
                accept=".jpg,.png"
                :auto-upload="false"
                :multiple="false"
                :on-change="
                  (file, fileList) => imageSuccessFun(file, fileList, 'logo')
                "
                :on-remove="
                  (file, fileList) => imageListRemove(file, fileList, 'logo')
                "
                :file-list="logo.list"
                :limit="2"
              >
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，且不超过240kb
                </div>
              </el-upload>
            </el-form-item></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="12">
            <!-- 公众号二维码 -->
            <el-form-item label="公众号二维码：">
              <el-upload
                ref="officialAccountRef"
                action
                list-type="picture-card"
                class="upload-demo"
                accept=".jpg,.png"
                :auto-upload="false"
                :multiple="false"
                :on-change="
                  (file, fileList) =>
                    imageSuccessFun(file, fileList, 'publicQrCode')
                "
                :on-remove="
                  (file, fileList) =>
                    imageListRemove(file, fileList, 'publicQrCode')
                "
                :file-list="publicQrCode.list"
                :limit="2"
              >
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，且不超过240kb
                </div>
              </el-upload>
            </el-form-item></el-col
          >
          <el-col :span="12"
            ><!-- 企业微信二维码 -->
            <el-form-item label="企业微信二维码：">
              <el-upload
                ref="weComRef"
                action
                list-type="picture-card"
                class="upload-demo"
                accept=".jpg,.png"
                :auto-upload="false"
                :multiple="false"
                :on-change="
                  (file, fileList) =>
                    imageSuccessFun(file, fileList, 'companyQrCode')
                "
                :on-remove="
                  (file, fileList) =>
                    imageListRemove(file, fileList, 'companyQrCode')
                "
                :file-list="companyQrCode.list"
                :limit="2"
              >
                <i class="el-icon-plus"></i>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，且不超过240kb
                </div>
              </el-upload>
            </el-form-item></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="关注公众号：" required>
              <div>
                <el-checkbox
                  v-model="form.payShowMpQrCode"
                  :true-label="1"
                  :false-label="0"
                  label="支付后关注"
                  @change="selectShowMpQrCode"
                ></el-checkbox>
                <p class="attention">
                  用户使用小程序支付后，展示渠道公众号二维码。仅支持定制客运、汽车票
                </p>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="客服电话："
              prop="customerServicePhone"
              class="form-phone"
            >
              <div
                v-for="(item, index) in form.customerServicePhone"
                :key="index"
                style="position: relative"
              >
                <el-input
                  v-model.trim="form.customerServicePhone[index]"
                  maxlength="11"
                  placeholder="请输入客服电话号码"
                  onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                ></el-input>
                <el-button
                  v-show="form.customerServicePhone.length < 5"
                  class="add"
                  @click="addNewPhone"
                  >+</el-button
                >
                <el-button
                  v-show="index >= 1"
                  class="reduce"
                  @click="deletePhone(index)"
                  >-</el-button
                >
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="启用：" prop="status">
            <el-switch
              v-model="form.status"
              :active-value="0"
              :inactive-value="1"
              active-color="#13ce66"
              inactive-color="#E3E3E3"
            ></el-switch> </el-form-item
        ></el-row>
        <h4>包车授权</h4>
        <el-row>
          <el-col :span="24">
            <el-form-item label="请选择授权渠道：" prop="authorizedChannelNos">
              <el-checkbox-group v-model="form.authorizedChannelNos">
                <el-checkbox
                  v-for="(item, index) in charterCarChanneList"
                  :key="index"
                  :label="item.channelNo"
                  :disabled="rowData.channelNo === item.channelNo"
                >
                  {{ item.channelName }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <h4>省交通厅数据对接</h4>
        <el-row>
          <el-form-item label="数据对接：" required>
            <el-switch
              v-model="form.sjttOpen"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#E3E3E3"
              @change="changeStatus"
            ></el-switch>
            <span class="tip">启用前请先填写对接信息</span>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="公司名称：" prop="sjttCompanyName">
              <el-input
                v-model.trim="form.sjttCompanyName"
                placeholder="请输入"
                maxlength="50"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="平台名称：" prop="sjttPlatformName">
              <el-input
                v-model.trim="form.sjttPlatformName"
                placeholder="请输入"
                maxlength="50"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="平台联系人：" prop="sjttLinkMan">
              <el-input
                v-model.trim="form.sjttLinkMan"
                placeholder="请输入"
                maxlength="20"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12">
            <el-form-item label="联系人电话：" prop="sjttLinkPhone">
              <el-input
                v-model.trim="form.sjttLinkPhone"
                maxlength="11"
                placeholder="请输入"
                onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="12"
            ><el-form-item label="appid：">
              <el-input
                v-model.trim="form.sjttAppid"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="12"
            ><el-form-item label="appSecret：">
              <el-input
                v-model.trim="form.sjttAppsecret"
                placeholder="请输入"
              ></el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
      <span v-cloak slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="closePopupFun"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="confirmFun"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 查看大图 -->
    <div
      v-show="dialogVisible"
      class="imgPopup"
      @click="() => (dialogVisible = false)"
    >
      <img :src="dialogImageUrl" alt @click.stop="() => {}" />
    </div>
  </div>
</template>

<script>
import host from "@/api/static/host.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  uploadFileAPI,
  getCompanyTree,
  queryChannelListAPI,
  queryAllChannelListAPI,
  updateChannelAPI,
  saveChannelAPI,
  openInvoceListAPI,
} from "@/api/lib/api.js";
const regPhone = /^(([0][1-9]{2,3}[0-9]{5,10})|(1(3|4|5|6|7|8|9)\d{9}))$/;
export default {
  name: "",
  components: { companySelect },
  props: {},
  data() {
    //校验logo
    var validateLogo = (rule, value, callback) => {
      if (this.logo.imgBase64 == "") {
        if (this.logo.list.length > 0 && this.logo.list[0].size == undefined) {
          callback();
        } else {
          callback("请上传logo图片");
        }
      } else {
        if (this.logo.imgMsg === "jpg" || this.logo.imgMsg === "png") {
          if (this.logo.imgSize < 245760) {
            callback();
          } else {
            callback("图片大小超过限制！");
          }
        } else {
          callback("上传文件格式不正确！");
        }
      }
    };
    //校验客服电话
    var validatePhone1 = (rule, value, callback) => {
      let index = value.length - 1;
      let val = value[index];
      if (val === "" && value.length === 1) {
        callback();
      } else if (val === "") {
        callback(new Error("请输入客服电话号码"));
      } else if (!regPhone.test(val)) {
        callback(new Error("客服电话号码格式错误"));
      } else {
        callback();
      }
    };
    //校验联系人电话
    var validatePhone2 = (rule, value, callback) => {
      if (this.form.sjttOpen) {
        if (value === "") {
          callback(new Error("请输入联系人电话号码"));
        } else if (!regPhone.test(value)) {
          callback(new Error("联系人电话号码格式错误"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var validatesjtt = (text, value, callback) => {
      if (this.form.sjttOpen) {
        if (value === "") callback(new Error(`请输入${text}`));
        else callback();
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      dialogImageUrl: "",
      tableData: [],
      titleName: [
        {
          title: "渠道代码",
          props: "channelNo",
          width: 200,
        },
        {
          title: "渠道名称",
          props: "channelName",
        },
        {
          title: "所属公司",
          props: "companyName",
        },
        {
          title: "LOGO",
          props: "logo",
          render: (h, params, index) => {
            return h("i", {
              class: "iconfont its_gy_image",
              style: {
                "font-size": "18px",
                cursor: "pointer",
                color: "#336FFE",
              },
              on: {
                click: () => {
                  this.dialogVisible = true;
                  this.dialogImageUrl = host.imgURL + params.logo;
                },
              },
            });
          },
        },
        {
          title: "公众号二维码",
          props: "publicQrCode",
          render: (h, params, index) => {
            return params.publicQrCode
              ? h("i", {
                  class: "iconfont its_gy_image",
                  style: {
                    "font-size": "18px",
                    cursor: "pointer",
                    color: "#336FFE",
                  },
                  on: {
                    click: () => {
                      this.dialogVisible = true;
                      this.dialogImageUrl = host.imgURL + params.publicQrCode;
                    },
                  },
                })
              : "";
          },
        },
        {
          title: "企业微信二维码",
          props: "companyQrCode",
          render: (h, params, index) => {
            return params.companyQrCode
              ? h("i", {
                  class: "iconfont its_gy_image",
                  style: {
                    "font-size": "18px",
                    cursor: "pointer",
                    color: "#336FFE",
                  },
                  on: {
                    click: () => {
                      this.dialogVisible = true;
                      this.dialogImageUrl = host.imgURL + params.companyQrCode;
                    },
                  },
                })
              : "";
          },
        },
        {
          title: "结算日",
          props: "settlementDay",
          SpecialJudgment: (res) => `每月${res}日`,
          width: 100,
        },
        {
          title: "客服电话",
          props: "customerServicePhone",
          width: 150,
        },
        {
          title: "快客码链接",
          props: "kkCodeLink",
          width: 200,
        },
        {
          title: "邀请有礼链接",
          props: "invitationLink",
          width: 200,
        },
        {
          title: "优惠券推广链接",
          props: "smartLink",
          width: 200,
        },
        // {
        //   title: "投诉反馈链接",
        //   props: "invitationLink",
        //   width: 200,
        // },
        {
          title: "小程序APPID",
          props: "appId",
          width: 150,
        },
        {
          title: "小程序secret",
          props: "secret",
          width: 150,
        },
        {
          title: "启用",
          props: "status",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status === 0 ? true : false,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  let obj = this.deepClone(params);
                  obj.status = obj.status == 1 ? 0 : 1;
                  updateChannelAPI(obj).then((res) => {
                    if (res.code == "SUCCESS") {
                      this.$message.success("修改成功");
                      this.getQueryChannelList();
                    }
                  });
                },
              },
            });
          },
        },
      ],
      showPopup: false,
      headerText: "",
      businessList: [],
      charterCarChanneList: [],
      form: {
        channelNo: "", //渠道代码
        channelName: "", //渠道名称
        companyName: null, //公司名称
        companyId: null, //公司ID
        customerServicePhone: [""], //客服电话
        status: "1", //是否启用
        kkCodeLink: null,
        settlementDay: 1,
        invitationLink: null,
        appId: null,
        secret: null,
        smartLink: "",
        sjttOpen: 0,
        sjttPlatformName: "",
        sjttCompanyName: "",
        sjttLinkMan: "",
        sjttLinkPhone: "",
        sjttAppid: "",
        sjttAppsecret: "",
        invoiceMethod: 1,
        invoiceBusinessType: [],
        payShowMpQrCode: 0,
        authorizedChannelNos: [],
      },
      rules: {
        settlementDay: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback("请输入结算日");
              } else if (value > 31 || value <= 0 || /\./.test(value)) {
                callback("请输入1-31的整数");
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        channelNo: [
          { required: true, message: "请输入渠道代码", trigger: "blur" },
        ],
        channelName: [
          { required: true, message: "请输入渠道名称", trigger: "blur" },
        ],
        kkCodeLink: [
          { required: true, message: "请输入快客码链接", trigger: "blur" },
        ],
        invitationLink: [
          { required: true, message: "请输入邀请有礼链接", trigger: "blur" },
        ],
        // invitationLink: [
        //   { required: true, message: "请输入投诉反馈链接", trigger: "blur" },
        // ],
        smartLink: [
          { required: true, message: "请输入优惠券推广链接", trigger: "blur" },
        ],
        appId: [
          { required: true, message: "请输入小程序APPID", trigger: "blur" },
        ],
        secret: [
          { required: true, message: "请输入小程序secret", trigger: "blur" },
        ],
        companyId: [
          { required: true, message: "请选择所属公司", trigger: "blur" },
        ],
        invoiceMethod: [
          { required: true, message: "请选择开票方式", trigger: "blur" },
        ],
        invoiceBusinessType: [
          { required: true, message: "请选择开票业务", trigger: "blur" },
        ],
        logo: [{ required: true, validator: validateLogo, trigger: "blur" }],
        customerServicePhone: [{ validator: validatePhone1, trigger: "blur" }],
        sjttLinkPhone: [{ validator: validatePhone2, trigger: "blur" }],
        sjttCompanyName: [
          {
            validator: (rule, value, callback) =>
              validatesjtt("公司名称", value, callback),
            trigger: "blur",
          },
        ],
        sjttPlatformName: [
          {
            validator: (rule, value, callback) =>
              validatesjtt("平台名称", value, callback),
            trigger: "blur",
          },
        ],
        sjttLinkMan: [
          {
            validator: (rule, value, callback) =>
              validatesjtt("联系人名称", value, callback),
            trigger: "blur",
          },
        ],
      },
      companyList: [],
      logo: {
        list: [],
        imgMsg: "",
        imgSize: 0,
        imgBase64: "",
        imgURL: "",
      }, //logo
      publicQrCode: {
        list: [],
        imgMsg: "",
        imgSize: 0,
        imgBase64: "",
        imgURL: "",
      }, //公众号二维码
      companyQrCode: {
        list: [],
        imgMsg: "",
        imgSize: 0,
        imgBase64: "",
        imgURL: "",
      }, //企业微信二维码
      disabledArray: [1],
      rowData: {},
    };
  },
  filters: {},
  computed: {},
  watch: {
    showPopup(val) {
      if (!val) {
        this.headerText = this.$options.data().headerText;
        this.form = this.$options.data().form;
        this.logo = this.$options.data().logo;
        this.publicQrCode = this.$options.data().publicQrCode;
        this.companyQrCode = this.$options.data().companyQrCode;
        this.rowData = {};
      } else {
        this.getQueryAllChannelList();
      }
    },
    dialogVisible(val) {
      if (!val) {
        this.dialogImageUrl = "";
      }
    },
  },
  created() {},
  mounted() {
    this.getCompanyList();
    this.getQueryChannelList();
  },
  methods: {
    //获取渠道指定数据
    getQueryChannelList() {
      this.disabledArray = [1];
      queryChannelListAPI({}).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
          res.data.forEach((e) => {
            this.disabledArray.push(e.companyId);
          });
        }
      });
      openInvoceListAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.businessList = res.data.filter((e) => !e.status);
        }
      });
    },
    //获取渠道全部数据
    getQueryAllChannelList() {
      queryAllChannelListAPI({}).then((res) => {
        if (res.code == "SUCCESS") {
          this.charterCarChanneList = res.data;
        }
      });
    },
    //所属公司选择回调
    getAddGroupIdShow(value) {
      this.form.companyId = value;
      this.$refs.form.validateField("companyId");
    },
    getCompanyName(value) {
      this.form.companyName = value;
      this.$refs.form.validateField("companyId");
    },
    // 获取所属公司数据回调
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    //新增客服电话
    addNewPhone() {
      const len = this.form.customerServicePhone.length - 1;
      if (this.form.customerServicePhone[len].trim() == "") {
        this.$message.error("请输入客服号码后再进行添加");
      } else {
        this.form.customerServicePhone.push("");
      }
    },
    //删除其他客服电话
    deletePhone(index) {
      this.form.customerServicePhone.splice(index, 1);
      this.$refs.form.validateField("customerServicePhone");
    },
    addFun() {
      this.headerText = "新增";
      this.showPopup = true;
    },
    updateBtn(row) {
      this.headerText = "编辑";
      this.showPopup = true;
      this.rowData = row;
      this.$nextTick(() => {
        this.form = this.deepClone(row);
        this.form.invoiceBusinessType = [];
        row.invoiceBusinessType.forEach((e) => {
          if (this.businessList.find((i) => i.businessType == e))
            this.form.invoiceBusinessType.push(e);
        });
        this.form.customerServicePhone =
          this.form.customerServicePhone.split(",");
        this.logo.imgURL = row.logo;
        this.publicQrCode.imgURL = row.publicQrCode;
        this.companyQrCode.imgURL = row.companyQrCode;
      });
      this.logo.list = row.logo ? [{ url: host.imgURL + row.logo }] : [];
      this.publicQrCode.list = row.publicQrCode
        ? [{ url: host.imgURL + row.publicQrCode }]
        : [];
      this.companyQrCode.list = row.companyQrCode
        ? [{ url: host.imgURL + row.companyQrCode }]
        : [];
    },
    //关闭弹窗
    closePopupFun() {
      this.showPopup = false;
    },
    confirmFun() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = this.deepClone(this.form);
          params.logo = this.logo.imgURL;
          params.publicQrCode = this.publicQrCode.imgURL;
          params.companyQrCode = this.companyQrCode.imgURL;
          params.customerServicePhone = params.customerServicePhone.join(",");
          params.authorizedChannelNos.length == 0 &&
            params.authorizedChannelNos.push(params.channelNo);
          let path =
            this.headerText === "新增" ? saveChannelAPI : updateChannelAPI;
          let msg = this.headerText === "新增" ? "添加成功" : "修改成功";
          path(params).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message.success(msg);
              this.showPopup = false;
              this.getQueryChannelList();
            }
          });
        }
      });
    },
    // 选择完图片后转为base64并保存
    imageSuccessFun(file, fileList, type) {
      this[type].list = [file];
      this[type].imgMsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      this[type].imgSize = file.size;
      var a = new FileReader();
      a.readAsDataURL(file.raw); //读取文件保存在result中
      a.onload = (e) => {
        this[type].imgBase64 = e.target.result; //读取的结果在result中
        type === "logo" && this.$refs.form.validateField("logo");
      };
      if (
        this[type].imgSize >= 245760 &&
        (this[type].imgMsg != "jpg" || this[type].imgMsg != "png")
      )
        return;
      // FormData 对象
      let form = new FormData();
      form.append("files", this[type].list[0].raw);
      console.log(this[type].list[0].raw);
      uploadFileAPI(form).then((res) => {
        if (res.code == "SUCCESS") {
          this[type].imgURL = res.data[0];
        }
      });
    },
    // 移除图片事件
    imageListRemove(file, fileList, type) {
      this[type].imgBase64 = "";
      this[type].imgMsg = "";
      this[type].imgSize = 0;
      this[type].list = [];
      this[type].imgURL = "";
      type === "logo" && this.$refs.form.validateField("logo");
      type === "publicQrCode" && (this.form.payShowMpQrCode = 0);
    },
    changeStatus(val) {
      if (val) {
        if (
          this.form.sjttPlatformName &&
          this.form.sjttCompanyName &&
          this.form.sjttLinkMan &&
          this.form.sjttLinkPhone
        ) {
          this.form.sjttOpen = 1;
        } else {
          this.form.sjttOpen = 0;
          this.$message.warning("请先填写对接信息");
        }
      } else {
        this.form.sjttOpen = 0;
      }
    },
    selectShowMpQrCode(val) {
      if (val && !this.publicQrCode.imgURL) {
        this.$message.warning("请先上传公众号二维码");
        this.$nextTick(() => {
          this.form.payShowMpQrCode = 0;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.channel-config-page {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  .Table {
    margin-top: 20px;
  }
  .channelForm {
    .tip {
      font-size: 11px;
      margin-left: 10px;
      color: #a7a7a7;
    }
    .little {
      width: 100px;
      .el-input__inner {
        width: 100px;
      }
    }
    .form-phone {
      .el-select,
      .el-input {
        width: 200px;
      }
      button {
        margin-left: 10px;
      }
      .reduce,
      .add {
        position: absolute;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        padding: 0 !important;
        bottom: 8px;
        left: 210px;
      }
    }
    .attention {
      line-height: 10px;
      font-size: 10px;
    }
  }
  .imgPopup {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
    img {
      max-width: 70%;
      max-height: 70%;
    }
  }
}
</style>
