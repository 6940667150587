var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "channel-config-page" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "small" },
          on: { click: _vm.addFun },
        },
        [_vm._v("添加")]
      ),
      _c("Table", {
        attrs: {
          "table-data": _vm.tableData,
          "title-name": _vm.titleName,
          operation: true,
          "col-class-type": true,
          showOverflowTooltip: false,
          "operation-width": "150",
        },
        on: {
          "update:tableData": function ($event) {
            _vm.tableData = $event
          },
          "update:table-data": function ($event) {
            _vm.tableData = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small", sort: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.updateBtn(scope.scopeRow)
                      },
                    },
                  },
                  [_vm._v("编辑")]
                ),
              ]
            },
          },
        ]),
      }),
      _vm.showPopup
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.headerText,
                visible: _vm.showPopup,
                width: "1400px",
                "close-on-click-modal": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showPopup = $event
                },
                close: _vm.closePopupFun,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "channelForm",
                  attrs: {
                    model: _vm.form,
                    inline: "",
                    rules: _vm.rules,
                    "label-width": "140px",
                    "label-position": "right",
                  },
                },
                [
                  _c("h4", [_vm._v("基本信息")]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "渠道代码：", prop: "channelNo" },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "15",
                                  disabled: _vm.headerText === "编辑",
                                  placeholder: "请输入",
                                },
                                model: {
                                  value: _vm.form.channelNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "channelNo",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.channelNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "渠道名称：",
                                prop: "channelName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  placeholder: "请输入",
                                },
                                model: {
                                  value: _vm.form.channelName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "channelName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.channelName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "所属公司：", prop: "companyId" },
                            },
                            [
                              _c("companySelect", {
                                ref: "companySelectRef",
                                attrs: {
                                  "company-tree": _vm.companyList,
                                  valuename: _vm.form.companyName,
                                  value: _vm.form.companyId,
                                  "disabled-array": _vm.disabledArray,
                                  disabled: _vm.headerText == "编辑",
                                },
                                on: {
                                  getValue: _vm.getAddGroupIdShow,
                                  getName: _vm.getCompanyName,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "结算日：",
                                prop: "settlementDay",
                              },
                            },
                            [
                              _vm._v(" 每月 "),
                              _c("el-input", {
                                staticClass: "little",
                                attrs: {
                                  placeholder: "请输入",
                                  type: "number",
                                },
                                model: {
                                  value: _vm.form.settlementDay,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "settlementDay",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.settlementDay",
                                },
                              }),
                              _vm._v("号 "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "快客码链接：",
                                prop: "kkCodeLink",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.form.kkCodeLink,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "kkCodeLink",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.kkCodeLink",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "邀请有礼链接：",
                                prop: "invitationLink",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.form.invitationLink,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "invitationLink",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.invitationLink",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "优惠券推广链接·：",
                                prop: "smartLink",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.form.smartLink,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "smartLink",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.smartLink",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "小程序APPID：", prop: "appId" },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.form.appId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "appId",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.appId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "小程序secret：",
                                prop: "secret",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.form.secret,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "secret",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.secret",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "开票方式：",
                                prop: "invoiceMethod",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.form.invoiceMethod,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "invoiceMethod", $$v)
                                    },
                                    expression: "form.invoiceMethod",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("电子发票"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("仅提交开票信息"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 3 } }, [
                                    _vm._v("不开票"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 4 } }, [
                                    _vm._v("数电发票"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _vm.form.invoiceMethod != 3
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "开票业务：",
                                    prop: "invoiceBusinessType",
                                  },
                                },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      model: {
                                        value: _vm.form.invoiceBusinessType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "invoiceBusinessType",
                                            $$v
                                          )
                                        },
                                        expression: "form.invoiceBusinessType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.businessList,
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          { key: index },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  label: item.businessType,
                                                },
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "LOGO：", prop: "logo" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "logoRef",
                                  staticClass: "upload-demo",
                                  attrs: {
                                    action: "",
                                    "list-type": "picture-card",
                                    accept: ".jpg,.png",
                                    "auto-upload": false,
                                    multiple: false,
                                    "on-change": (file, fileList) =>
                                      _vm.imageSuccessFun(
                                        file,
                                        fileList,
                                        "logo"
                                      ),
                                    "on-remove": (file, fileList) =>
                                      _vm.imageListRemove(
                                        file,
                                        fileList,
                                        "logo"
                                      ),
                                    "file-list": _vm.logo.list,
                                    limit: 2,
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [
                                      _vm._v(
                                        " 只能上传jpg/png文件，且不超过240kb "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "公众号二维码：" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "officialAccountRef",
                                  staticClass: "upload-demo",
                                  attrs: {
                                    action: "",
                                    "list-type": "picture-card",
                                    accept: ".jpg,.png",
                                    "auto-upload": false,
                                    multiple: false,
                                    "on-change": (file, fileList) =>
                                      _vm.imageSuccessFun(
                                        file,
                                        fileList,
                                        "publicQrCode"
                                      ),
                                    "on-remove": (file, fileList) =>
                                      _vm.imageListRemove(
                                        file,
                                        fileList,
                                        "publicQrCode"
                                      ),
                                    "file-list": _vm.publicQrCode.list,
                                    limit: 2,
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [
                                      _vm._v(
                                        " 只能上传jpg/png文件，且不超过240kb "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "企业微信二维码：" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "weComRef",
                                  staticClass: "upload-demo",
                                  attrs: {
                                    action: "",
                                    "list-type": "picture-card",
                                    accept: ".jpg,.png",
                                    "auto-upload": false,
                                    multiple: false,
                                    "on-change": (file, fileList) =>
                                      _vm.imageSuccessFun(
                                        file,
                                        fileList,
                                        "companyQrCode"
                                      ),
                                    "on-remove": (file, fileList) =>
                                      _vm.imageListRemove(
                                        file,
                                        fileList,
                                        "companyQrCode"
                                      ),
                                    "file-list": _vm.companyQrCode.list,
                                    limit: 2,
                                  },
                                },
                                [
                                  _c("i", { staticClass: "el-icon-plus" }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [
                                      _vm._v(
                                        " 只能上传jpg/png文件，且不超过240kb "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "关注公众号：", required: "" } },
                            [
                              _c(
                                "div",
                                [
                                  _c("el-checkbox", {
                                    attrs: {
                                      "true-label": 1,
                                      "false-label": 0,
                                      label: "支付后关注",
                                    },
                                    on: { change: _vm.selectShowMpQrCode },
                                    model: {
                                      value: _vm.form.payShowMpQrCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "payShowMpQrCode",
                                          $$v
                                        )
                                      },
                                      expression: "form.payShowMpQrCode",
                                    },
                                  }),
                                  _c("p", { staticClass: "attention" }, [
                                    _vm._v(
                                      " 用户使用小程序支付后，展示渠道公众号二维码。仅支持定制客运、汽车票 "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-phone",
                              attrs: {
                                label: "客服电话：",
                                prop: "customerServicePhone",
                              },
                            },
                            _vm._l(
                              _vm.form.customerServicePhone,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: { position: "relative" },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "11",
                                        placeholder: "请输入客服电话号码",
                                        onkeyup:
                                          "this.value = this.value.replace(/[^\\d]/g,'');",
                                      },
                                      model: {
                                        value:
                                          _vm.form.customerServicePhone[index],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form.customerServicePhone,
                                            index,
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression:
                                          "form.customerServicePhone[index]",
                                      },
                                    }),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.form.customerServicePhone
                                                .length < 5,
                                            expression:
                                              "form.customerServicePhone.length < 5",
                                          },
                                        ],
                                        staticClass: "add",
                                        on: { click: _vm.addNewPhone },
                                      },
                                      [_vm._v("+")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: index >= 1,
                                            expression: "index >= 1",
                                          },
                                        ],
                                        staticClass: "reduce",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deletePhone(index)
                                          },
                                        },
                                      },
                                      [_vm._v("-")]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "启用：", prop: "status" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 0,
                              "inactive-value": 1,
                              "active-color": "#13ce66",
                              "inactive-color": "#E3E3E3",
                            },
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("h4", [_vm._v("包车授权")]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "请选择授权渠道：",
                                prop: "authorizedChannelNos",
                              },
                            },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.form.authorizedChannelNos,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "authorizedChannelNos",
                                        $$v
                                      )
                                    },
                                    expression: "form.authorizedChannelNos",
                                  },
                                },
                                _vm._l(
                                  _vm.charterCarChanneList,
                                  function (item, index) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: index,
                                        attrs: {
                                          label: item.channelNo,
                                          disabled:
                                            _vm.rowData.channelNo ===
                                            item.channelNo,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.channelName) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("h4", [_vm._v("省交通厅数据对接")]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数据对接：", required: "" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              "active-color": "#13ce66",
                              "inactive-color": "#E3E3E3",
                            },
                            on: { change: _vm.changeStatus },
                            model: {
                              value: _vm.form.sjttOpen,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "sjttOpen", $$v)
                              },
                              expression: "form.sjttOpen",
                            },
                          }),
                          _c("span", { staticClass: "tip" }, [
                            _vm._v("启用前请先填写对接信息"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "公司名称：",
                                prop: "sjttCompanyName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.form.sjttCompanyName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "sjttCompanyName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.sjttCompanyName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "平台名称：",
                                prop: "sjttPlatformName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入",
                                  maxlength: "50",
                                },
                                model: {
                                  value: _vm.form.sjttPlatformName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "sjttPlatformName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.sjttPlatformName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "平台联系人：",
                                prop: "sjttLinkMan",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入",
                                  maxlength: "20",
                                },
                                model: {
                                  value: _vm.form.sjttLinkMan,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "sjttLinkMan",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.sjttLinkMan",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "联系人电话：",
                                prop: "sjttLinkPhone",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "11",
                                  placeholder: "请输入",
                                  onkeyup:
                                    "this.value = this.value.replace(/[^\\d]/g,'');",
                                },
                                model: {
                                  value: _vm.form.sjttLinkPhone,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "sjttLinkPhone",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.sjttLinkPhone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "appid：" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.form.sjttAppid,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "sjttAppid",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.sjttAppid",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "appSecret：" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.form.sjttAppsecret,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "sjttAppsecret",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "form.sjttAppsecret",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: { click: _vm.closePopupFun },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.confirmFun },
                    },
                    [_vm._v("保存")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dialogVisible,
              expression: "dialogVisible",
            },
          ],
          staticClass: "imgPopup",
          on: { click: () => (_vm.dialogVisible = false) },
        },
        [
          _c("img", {
            attrs: { src: _vm.dialogImageUrl, alt: "" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return (() => {}).apply(null, arguments)
              },
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }